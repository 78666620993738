<template>
    <section class="dtc-main-section mt-2 mr-2">
      <LocalStuDataEdit @updateLocalStuData="getData"></LocalStuDataEdit>
      <section class="search-block">
        <h5 class="big-title">統一分發結果</h5>
        <div class="dtc-search1">
          <b-input-group prepend="姓名">
            <b-input
              v-model.trim="searchName"
              placeholder="請輸入姓名"
              @keydown.enter="searchDb"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="身分證字號">
            <b-input
              v-model.trim="searchId"
              placeholder="身分證字號"
              @keydown.enter="searchDb"
            ></b-input>
          </b-input-group>
          <b-button variant="primary" @click="searchDb">進行查詢</b-button>
          <b-button variant="danger" @click="clearSearch">清除條件</b-button>
          <b-button variant="warning" @click="getExcel" v-if="!loadingExcel">匯出清單</b-button>
          <b-button variant="warning" disabled v-else><b-spinner small type="grow"></b-spinner>Loading...</b-button>
          <b-button variant="success"  @click="$refs.file.click()">匯入資料</b-button>
          <input  ref="file" type="file" accept=".xlsx, .xls" @change="fileChange" hidden />
        </div>
      </section>
  
      <header class="dtc-grid-header my-dark">
        <div
          v-for="(item, i) in headers"
          :key="`headers${i}`"
          :title="item.name || item"
          @click="sort(item)"
        >
          {{ item.name || item }}
          <span v-show="item.sortDesc === null">
            <i class="fas fa-sort"></i>
          </span>
          <span v-show="item.sortDesc === false">
            <i class="fas fa-sort-up"></i>
          </span>
          <span v-show="item.sortDesc">
            <i class="fas fa-sort-down"></i>
          </span>
        </div>
      </header>
      <!-- <pre>{{items[0]}}</pre> -->
  
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color: #39312e"
        :style=" i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'"
      >
  
        <div :title="item.BYear">
          {{ item.BYear }}
        </div>
        <div :title="item.SchoolName">
          {{ item.SchoolName }}
        </div>
        <div :title="item.MajorName">
          {{ item.MajorName }}
        </div>
        <div :title="item.Country">
          {{ item.Country }}
        </div>
        <div :title="item.ExamNumber">
          {{ item.ExamNumber }}
        </div>
        <div :title="item.Name">
          {{ item.Name }}
        </div>
        <div :title="item.Identifier">
          {{ item.Identifier }}
        </div>
        <div :title="item.Admission">
          {{ item.Admission }}
        </div>
        <div :title="item.CreateDate">
          {{ item.CreateDate }}
        </div>
  
      </main>
  
      <footer class="dtx-footer">
        <b-pagination
          v-model="currentPageNum"
          :total-rows="rowCount"
          :per-page="pagingRowPerPage"
          aria-controls="dataTable1"
          align="center"
          class="mt-2"
        >
          <span slot="first-text">
            <i class="fas fa-fast-backward"></i>
          </span>
          <span slot="prev-text">
            <i class="fas fa-step-backward"></i>
          </span>
          <span slot="next-text">
            <i class="fas fa-step-forward"></i>
          </span>
          <span slot="last-text">
            <i class="fas fa-fast-forward"></i>
          </span>
          <span slot="page" slot-scope="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <i v-else>{{ page }}</i>
          </span>
        </b-pagination>
        <b-select
          :options="rows"
          class="mt-2"
          v-model="pagingRowPerPage"
        ></b-select>
        <div class="mt-3">{{ totalCountStr }}</div>
      </footer>
    </section>
  </template>
  
  <script>
  import queryString from "query-string";
  import buildQuery from "odata-query";
  import { store } from "@/store/global.js";
  import moment from "moment";
  import LocalStuDataEdit from "@/components/systemmanage/LocalStuDataEdit.vue";
  
  
  import {
    genderObject,
    countriesObject,
    localStatusObject,
    allServiceObject,
    domainObject,
  } from "@/constant.js";
  
  const headers = [
    "年度",
    "分發學校",
    "學系",
    "籍屬",
    "學測應試號碼",
    "考生姓名",
    "身分證字號",
    "是否放棄入學",
    "建立時間",
  ];
  const zero = "T00:00:00";
  const rows = [10, 20, 50];
  const year = new Date().getFullYear() - 1911;
  let years = new Array(9).fill().map((s, i) => year + 1 - i);
  years.unshift({ value: null, text: "全部" });
  // schoolObject.unshift({ value: null, text: "全部" });
  // majorObject.unshift({ value: null, text: "全部" });
  // countryObject.unshift({ value: null, text: "全部" });
  
  export default {
    name: "downarea",
    data() {
      return {
        headers,
        items: [],
        keys: [],
        currentPageNum: 1,
        rowCount: 0,
        pagingRowPerPage: 20,
        orderBy: [],
        year: null,
        search: true,
        rows,
        totalCountStr: "",
        years,
        filename: "",
        fakeData: [],
        countryObject: [],
        genderObject,
        schoolObject: [],
        majorObject: [],
        countriesObject,
        localStatusObject,
        allServiceObject,
        hometownObject: [],
        serviceOption: [],
        searchName: "",
        searchId: "",
        searchGender: null,
        searchBirthday: {
          year: "",
          month: "",
          day: "",
        },
        searchSchool: null,
        searchEntryYear: null,
        searchEndYear: null,
        searchMajor: null,
        searchCategory: null,
        searchHometown: null,
        searchStatus: null,
        searchServiceNow: null,
  
        loadingExcel: false,
        domainObject,
  
        allHealthOption: [],
      };
    },
    components: {
      LocalStuDataEdit,
    },
    computed: {
      healthCodeCheck() {
        const loginHealthCode = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
        return schoolArr.includes(loginHealthCode);
      },
    },
  
    methods: {
      async getExcel() {
        const top = 500000;
        const skip = 0;
        const obj = { top, skip };
        let qs = buildQuery(obj);
        let bQs = false;
        if (this.searchName) {
          qs = this.$equalSubstringofFilter("Name", qs, this.searchName);
          bQs = true;
        }
  
        if (this.searchId) {
          if (bQs) {
            qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
          } else {
            qs = this.$equalSubstringofFilter("Identifier", qs, this.searchId);
          }
          bQs = true;
        }
        if (qs) {
          qs += `&Status=1`;  // 如果 qs 已經有其他參數，使用 &
        } else {
          qs = `?Status=1`;  // 如果 qs 是空的，使用 ?
        }
  
        const url = `ApplyResult/GetExportApplyResult${qs}`;
        try {
          this.loadingExcel = true;
          const res = await window.axios.get(url);
          this.loadingExcel = false;
          const excelUrl = `${this.domainObject.platformOriginal}${res}`;
          window.open(excelUrl);
        } catch (error) {
          this.$bvToast.toast(error, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      },
      async fileChange(e) {
      const status = 1;
      let form = new FormData();
      form.append("input",  e.target.files[0])
       e.target.value = '';
        try {
          const map = await window.axios.post(
             `ApplyResult/ImportApplyResult?status=${status}`,
              form
            );
          this.$bvToast.toast(`新增成功!!`, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.getData()
        } catch (e) {
          this.$bvToast.toast("新增失敗!! " + e, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      },
      sort(item) {
        if (!Boolean(item.name)) {
          return;
        }
        if (item.sortDesc) {
          item.sortDesc = null;
        } else if (false === item.sortDesc) {
          item.sortDesc = true;
        } else if (null === item.sortDesc) {
          item.sortDesc = false;
        }
        this.orderBy = [];
        this.headers.forEach((s) => {
          if (!Boolean(s.name)) {
            return;
          } else if (s.sortDesc !== null) {
            this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
          }
        });
        this.getData();
      },
  
      searchDb() {
        this.search = true;
        this.getData();
      },
      async clearSearch() {
        this.searchName = "";
        this.searchId = "";
        this.searchGender = null;
        this.searchBirthday = {
          year: "",
          month: "",
          day: "",
        };
        this.searchSchool = null;
        this.searchEntryYear = null;
        this.searchEndYear = null;
        this.searchMajor = null;
        this.searchCategory = null;
        this.searchHometown = null;
        this.searchStatus = null;
        this.searchServiceNow = null;
        this.getData();
      },
      async getData() {
  
        //odata3 qs
        const page = this.currentPageNum;
        const skip = (page - 1) * this.pagingRowPerPage;
        const top = this.pagingRowPerPage;
        const orderBy = this.orderBy;
        const obj = { top, skip, orderBy };
        let qs = buildQuery(obj);
        qs = `${qs}&$filter=Status eq 1`;
  
        // 使用者名稱篩選
        if (this.searchName) {
          qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
        }
  
        // 身分證字號篩選
        if (this.searchId) {
          qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
  
        }
  
        // 如果沒有條件，移除不必要的 $filter=
        //console.log(qs);
  
        try {
          const { Items, Count } = await window.axios.get(
            `ApplyResult/ApplyResultInfo${qs}`
          );
            // 修改資料處理部分
          this.items = Items.map((s) => ({
              BYear: s.BYear || "",
              SchoolName: s.SchoolName || "",
              MajorName: s.MajorName ||"",
              Country: s.Country || "",
              ExamNumber: s.ExamNumber || "",
              Name: s.Name || "",
              Identifier: s.Identifier || "",
              Status: s.Status || "",
              Admission: s.Admission  === "1" ? "是" : s.Gender === "0" ? "否" : "",
              CreateDate: s.CreateDate ? s.CreateDate.split("T")[0] : "",
            }));
          this.rowCount = Count;
          this.totalCountStr = `共${Count} 筆`;
        } catch (e) {
          this.$bvToast.toast(`${e}`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      },
    },
    async mounted() {
      await this.getData();
      this.$root.$on("get-Newest-LocalstuData", () => this.getData());
    },
  
    watch: {
      searchEntryYear(v) {
        if (+v < 0) {
          this.searchEntryYear = "0";
        }
        let value = v;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.searchEntryYear = result));
      },
      searchEndYear(v) {
        if (+v < 0) {
          this.searchEndYear = "0";
        }
        let value = v;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.searchEndYear = result));
      },
      "searchBirthday.year"(v) {
        if (+v < 0) {
          this.searchBirthday.year = "0";
        }
        let value = v;
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (v.length > 3) {
          value = value.substring(1, 4);
        }
        const result = value;
        this.$nextTick(() => (this.searchBirthday.year = result));
      },
      "searchBirthday.month"(v) {
        let value = v;
        if (+v < 0) {
          this.searchBirthday.month = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v == 10) {
          value = 10;
        } else if (+v == 11) {
          value = 11;
        } else if (+v >= 12) {
          value = 12;
        }
        const result = value;
        this.$nextTick(() => (this.searchBirthday.month = result));
      },
      "searchBirthday.day"(v) {
        let value = v;
        if (+v < 0) {
          this.searchBirthday.day = 0;
        }
        if (value.substring(0, 1) == "0") {
          value = value.substring(1);
        }
        if (+v >= 31) {
          value = 31;
        }
        const result = value;
        this.$nextTick(() => (this.searchBirthday.day = result));
      },
  
      currentPageNum(v) {
        this.currentPageNum = v;
        this.getData().catch((e) => {});
      },
      pagingRowPerPage(v) {
        this.getData().catch((e) => {});
      },
      searchStatus(v) {
        if(v){
          this.serviceOption = this.allServiceObject[v];
          this.searchServiceNow = v=='畢業'?'畢業':null;
        }else{
          this.searchServiceNow = null;
          this.serviceOption = [];
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  
  .uploadfile {
    background-color: #4cb150;
    color: white;
    width: 70px;
    height: 30px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px; 
    /* 使用 Flexbox 將按鈕水平和垂直置中 */
    align-self: center; /* 垂直居中 */
    justify-self: center; /* 水平居中 */
  }
  
  .uploadfile0 {
    background-color: #ef791e;
    color: white;
    width: 70px;
    height: 30px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px; 
    /* 使用 Flexbox 將按鈕水平和垂直置中 */
    align-self: center; /* 垂直居中 */
    justify-self: center; /* 水平居中 */
  }
  .dtc-main-section {
    // overflow-x: auto;
    padding: 12px;
    // position: relative;
    // height: 98vh;
  }
  .dtc-grid-header,
  .dtc-grid-header2 {
    width: 1180px;
    display: grid;
    grid-template-columns:
      repeat(1, 80px) 180px repeat(3, 120px) repeat(2, 150px) 120px 140px;
    grid-auto-flow: column;
    text-align: center;
    border-right: 0px;
  
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      /* word-break: keep-all; */
      -webkit-box-orient: vertical;
      font-size: 14px;
      padding: 4px 0;
      border-right: 1px solid #dee2e5;
      border-bottom: 1px solid #dee2e5;
      display: -webkit-box;
      height: 36px;
    }
    > div:first-child {
      border-left: 1px solid #dee2e5;
    }
  }
  .dtc-grid-header {
    > div {
      line-height: 30px;
    }
  }
  .dtc-grid-header2 {
    > div {
      font-size: 16px;
      padding: 6px;
      padding-top: 7px;
    }
  }
  
  .my-dark {
    > div {
      // background: var(--gray);
      background: #424242;
      color: white;
    }
  }
  .dtc-link {
    color: var(--primary);
    cursor: pointer;
  }
  .dtc-search,
  .dtc-search1 {
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: 343px repeat(3, 283px);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
  
  .dtc-search1 {
    grid-template-columns: 343px max-content repeat(30, max-content);
  }
  .dtx-footer {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    justify-content: center;
    grid-gap: 12px;
  }
  
  .input-group-text {
    width: 110px;
    background: #0379fd;
    color: #fff;
  }
  .file-content {
    text-align: start;
    padding-left: 10px !important;
    .subject {
      cursor: pointer;
      font-weight: 900;
    }
    .content {
      color: #777;
    }
  }
  .note {
    text-align: left;
    padding-left: 5px !important;
  }
  .search-birthday {
    display: grid;
    grid-template-columns: 80px 12.5px 70px 12.5px 70px;
    .dash {
      margin-top: 6px;
      text-align: center;
    }
  }
  .search-block {
    width: 1180px;
    height: 100px;
    margin-bottom: 10px;
    .big-title {
      background: #0379fd;
      padding: 10px;
      color: #fff;
      border-radius: 6px 6px 0 0;
    }
    .dtc-search,
    .dtc-search1 {
      padding-left: 10px;
    }
    background: #fbfcf9;
    border: 1px solid #bdbdbd;
    border-radius: 8px 8px 0 0;
  }
  </style>
  